
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 3.12001C10.0004 3.0542 9.98778 2.98897 9.96289 2.92806C9.93801 2.86714 9.90135 2.81173 9.85501 2.76501L7.73501 0.645008C7.68829 0.598668 7.63288 0.562005 7.57196 0.537123C7.51104 0.51224 7.44581 0.499628 7.38001 0.500008C7.31421 0.499628 7.24897 0.51224 7.18806 0.537123C7.12714 0.562005 7.07173 0.598668 7.02501 0.645008L5.61001 2.06001L0.145008 7.52501C0.0986677 7.57173 0.062005 7.62714 0.0371227 7.68806C0.0122404 7.74897 -0.000372089 7.81421 8.35715e-06 7.88001V10C8.35715e-06 10.1326 0.0526868 10.2598 0.146455 10.3536C0.240223 10.4473 0.3674 10.5 0.500008 10.5H2.62001C2.68997 10.5038 2.75995 10.4929 2.82542 10.4679C2.89088 10.4429 2.95036 10.4045 3.00001 10.355L8.43501 4.89001L9.85501 3.50001C9.90065 3.45155 9.93783 3.39577 9.96501 3.33501C9.96983 3.29515 9.96983 3.25486 9.96501 3.21501C9.96735 3.19173 9.96735 3.16828 9.96501 3.14501L10 3.12001ZM2.41501 9.50001H1.00001V8.08501L5.96501 3.12001L7.38001 4.53501L2.41501 9.50001ZM8.08501 3.83001L6.67001 2.41501L7.38001 1.71001L8.79001 3.12001L8.08501 3.83001Z" fill="#1D2433"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconEdit',
    inheritAttrs: true,
  }
  </script>
